<script setup lang="ts">
import Header from "../Components/Header.vue";
import Footer from "../Components/Footer.vue";
import { router, usePage } from "@inertiajs/vue3";
import { nextTick, onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { Vehicle, VehicleMake, VehicleModel, LandingData } from "@/types";
import { Head } from "@inertiajs/vue3";

const page = usePage();
const toast = useToast();

const showHeaderFooter = ref<boolean | undefined>(true);
const make = ref("");
const minPrice = ref();

router.on("navigate", (event) => {
    if (event.detail.page.url.startsWith("/simulation")) {
        showHeaderFooter.value = false;
    } else {
        showHeaderFooter.value = true;

        switch (page.component) {
            case "Vehicle/LandingMake":
                make.value = page.props.vehicleMake
                    ? (page.props.vehicleMake as VehicleMake).designation
                    : "";
                minPrice.value = 500;
                break;
            case "Vehicle/LandingModel":
                make.value =
                    page.props.vehicleMake && page.props.vehicleModel
                        ? (page.props.vehicleMake as VehicleMake).designation +
                          " " +
                          (page.props.vehicleModel as VehicleModel).designation
                        : "";
                minPrice.value = page.props.minMonthly;
                break;
            case "Vehicle/LandingModelSpecification":
                if (
                    (page.props.landingData as LandingData) &&
                    (page.props.landingData as LandingData).vehicleSpecification
                ) {
                    make.value =
                        (page.props.landingData as LandingData)
                            .vehicleSpecification?.label ?? "";
                } else {
                    make.value =
                        (page.props.landingData as LandingData).vehicleModel
                            .label ?? "";
                }
                minPrice.value = page.props.minMonthly;

                break;
            case "Vehicle/Show":
                make.value = (page.props.vehicle as Vehicle).display_name2;
                minPrice.value = page.props.minMonthly;
        }
    }
});

onMounted(() => {
    if (page.props.flash.toast) {
        // Sans le nextTick le toast ne marche pas
        nextTick(() => {
            toast(page.props.flash.toast.message, {
                type: page.props.flash.toast.type,
            });
        });
    }
});
</script>

<template>
    <Head>
        <title>Marketplace</title>
    </Head>
    <Header v-if="showHeaderFooter" />

    <main class="app-main">
        <slot></slot>
    </main>

    <Footer v-if="showHeaderFooter" :make="make" :min-price="minPrice" />
</template>

<style scoped>
.app-main {
    flex-grow: 1;
}
</style>
